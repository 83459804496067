import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from "./Routes/Root/Root";
import Stock from "./Routes/Stock/Stock";
import {About} from "./Routes/About/About";
import Register from "./Routes/Auth/Register"
import Login from "./Routes/Auth/Login"
import Logout from './Routes/Auth/Logout';
import { UserProvider } from './userContext';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />
    },
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/logout',
        element: <Logout />
    },
    {
        path: '/signup',
        element: <Register />
    },
    {
        path: '/signin',
        element: <Login />
    },
    {
        path: '/:name',
        element: <Stock />
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserProvider >
      <RouterProvider router={router}/>
    </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
