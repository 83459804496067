import './Header.css'
import Container from "../../components/Container/Container";
import logo from '../../static/logo.png'
import {Link} from "react-router-dom";
import { UserContext } from '../../userContext';
import {useContext, useEffect} from "react";
import axios from 'axios';
import { GET_PROFILE_INFO_URL, REFRESH_URL } from '../../Routes/Auth/Utils';

const Header = () => {

  const { user, setUser } = useContext(UserContext);

  const getUserInfo = async(access_token) => {
      try {
          const response = await axios.get(GET_PROFILE_INFO_URL,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${access_token.replace(/["]+/g, '')}`,

                  }
              },
          )
          return response.data
      } catch (error) {
          console.error('Error fetching user info', error)
      }
  }

  const refreshTokens = async() => {
      try {
          const response = await axios.post(REFRESH_URL, {},
              {
                  headers: {
                      'accept': 'application/json'
                  }
              }
          )
          const userInfo = await getUserInfo(response.data.access_token)
          setUser(
              {
                  'access_token': response.data.access_token,
                  'user': userInfo
              }
          )
      } catch (error) {}
  }

  useEffect(() => {
      refreshTokens();
  }, [])

  return (
      <header>
          <Container>
              <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4">
                  <Link to={"/"}>
                      <div className="logo-container">
                          <img style={{
                              marginLeft: "-25px"
                          }} src={logo} alt={"Rumberg logo"} />
                      </div>
                  </Link>
                  { !user ? (
                  <div className='nav'>
                    <div className='nav-item'>
                      <Link to={"/signin"} className='nav-link'>
                        <button type="button" className='btn btn-info'>
                          Войти
                        </button>
                      </Link>
                    </div>
                    <div className='nav-item'>
                      <Link to={"/signup"} className="nav-link">
                        <button type="button" className='btn btn-info'>
                          Зарегистрироваться
                        </button>
                      </Link>
                    </div>
                  </div>
                  ) : (
                    <div className='nav align-items-center'>
                      <div className='nav-item'>
                        <span className='badge rounded-pill bg-light text-dark'>{ user.user.email }</span>
                      </div>
                      <div className='nav-item'>
                        <Link to={"/logout"} className="nav-link">
                          <button type="button" className='btn btn-info'>
                            Выйти
                          </button>
                        </Link>
                      </div>                     
                    </div>
                  )}
              </div>
          </Container>
      </header>
  )
}

export default Header