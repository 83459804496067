import './Auth.css'
import { useForm } from "react-hook-form"
import { LOGIN_URL } from './Utils';
import axios from 'axios';
import { UserContext } from '../../userContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../static/logo.png'
import {Link} from "react-router-dom";

const Login = () => {
    const { setUser } = useContext(UserContext)
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = (data) => {
      axios.post(
        LOGIN_URL,
        {
          'email': data.email,
          'password': data.password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        setUser(
          {
            'access_token': response.data.access_token,
            'user': response.data.user,
          }
        )
        navigate('/')
      }).catch((error) => {
        if (error.response) {
          setError('email', {
            type: 'server',
            message: 'Пользователь с такой почтой не существует.',
          })
        }
      })

    }

    return (

        <div className="align-items-center justify-content-center vh-100">
          <div className="mask d-flex align-items-center h-100">
            <div className="container h-100">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                  <div className="card" style={{borderRadius: "15px"}}>
                    <div className='card-header'>
                        <Link to={"/"}>
                            <div className="logo-container mx-auto">
                                <img style={{
                                    marginLeft: "-25px"
                                }} src={logo} alt={"Rumberg logo"} />
                            </div>
                        </Link>
                    </div>
                    <div className="card-body p-5">
                      <h2 className="text-uppercase text-center mb-5">Вход</h2>

                      <form onSubmit={handleSubmit(onSubmit)}>

                        <div data-mdb-input-init className="form-outline mb-4">
                          <input type="email" id="form3Example3cg" className="form-control form-control-lg" {...register("email", { required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/})}/>
                          <label className="form-label" for="form3Example3cg">Ваша почта</label>
                          {errors.email && errors.email.type === "required" && (
                            <p className="errorMsg">Введите почту.</p>
                          )}
                          {errors.email && errors.email.type === "server" && (
                            <p className="errorMsg">{ errors.email.message }</p>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <p className="errorMsg">Почта некорректна.</p>
                          )}
                        </div>

                        <div data-mdb-input-init className="form-outline mb-4">
                          <input type="password" id="form3Example4cg" className="form-control form-control-lg" {...register("password", { required: true, minLength: 5})}/>
                          <label className="form-label" for="form3Example4cg">Пароль</label>
                          {errors.password && errors.password.type === "required" && (
                            <p className="errorMsg">Пароль обязателен.</p>
                          )}
                          {errors.password && errors.password.type === "minLength" && (
                            <p className="errorMsg">
                              Минимальная длина пароля 5 символов.
                            </p>
                          )}

                        </div>

                        <div className="d-flex justify-content-center">
                          <button  type="submit" data-mdb-button-init
                            data-mdb-ripple-init className="btn btn-info btn-block btn-lg">Войти</button>
                        </div>

                        <p className="text-center text-muted mt-5 mb-0">Нет аккаунта ? <a href="/signup"
                            className="fw-bold text-body"><u>Регистрация</u></a></p>

                      </form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default Login