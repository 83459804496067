import './Stock.css'
import Header from "../../Base/Header/Header";
import Footer from "../../Base/Footer/Footer";
import StockRoot from "./StockRoot/StockRoot";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../userContext';

const Stock = () => {
    const {name} = useParams()
    const { user } = useContext(UserContext)
    const [ hasAccess, setAccess ] = useState(true);

    useEffect(() => {
        var config = { 
            "Access-Control-Allow-Origin": "*"
        }
        if (user) {
            config = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.access_token.replace(/["]+/g, '')}`,
            "Access-Control-Allow-Origin": "*"
            }
        }

        axios(`/api/stock_check_access`, {
            data: {
                portfolio: name,
            },
            headers: config,
            method: "POST"
        }).catch(error => {
            setAccess(false)
        }) 
    }, [name])

    useEffect(() => {
        document.title = `Rumberg | ${name.toUpperCase()} Index`
    }, [])

     console.log(hasAccess)
    return (
            <div className={`stock`}>
                <Header/>
                {hasAccess ? (
                    <StockRoot name={name}/>
                ) : (
                    <div className='container'>
                        <div className='alert alert-danger text-center'>Отказано в доступе.</div>
                    </div>
                )}
                <Footer/>
            </div>
        )
}

export default Stock