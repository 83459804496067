import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import { UserContext } from "../../userContext";
import { useContext } from "react";
import axios from "axios";
import { LOGOUT_URL } from "./Utils";

const Logout = () => {
    const navigate = useNavigate()
    const { user, setUser } = useContext(UserContext)
    useEffect(() => {
        axios.post(LOGOUT_URL, {},
            {
                headers: {
                    'Authorization': `Bearer ${user.access_token.replace(/["]+/g, '')}`,
                    'accept': 'application/json'
                }
            }
        ).catch((error) => {})
        setUser()
        navigate("/")
    })
}

export default Logout